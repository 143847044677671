/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { useLocalePath, useSwitchLocalePath, useI18n as vueUseI18n } from '#imports'

export default function useI18n() {
  const { locale, localeCodes, t, n, d } = vueUseI18n()
  const localePath = useLocalePath()
  const switchLocalePath = useSwitchLocalePath()

  const localeNames: { [key: string]: string } = {
    nl: t('components.intl.languages.nl'),
    en: t('components.intl.languages.en'),
  }
  const localeName = localeNames[locale.value]
  const setLocale = (newLocale: string) => (locale.value = newLocale)

  return {
    locale,
    localeCodes,
    setLocale,
    localeName,
    localeNames,
    localePath,
    switchLocalePath,
    t,
    n,
    d,
  }
}
